<div class="search-container">
    <div class="search-form" role="search">
        <label for="searchInput" class="visually-hidden">Search Players, Videos & More</label>
        <div class="search-input-wrapper">
            <p-toggleButton class="custom-icon" [(ngModel)]="searchType" [onIcon]="'pi pi-video'" [offIcon]="'pi pi-user'" />
            <!-- <p-inputIcon (click)="toggleSearchType()" class="d-flex custom-icon"
                [styleClass]="(searchType == 0)?'pi pi-search':'pi pi-video'" /> -->
            <p-autoComplete [showClear]="true"
                [placeholder]="(searchType == 0)?'Search Players & Find Talents':'Search Videos & Discover Skills'"
                class="search-input" [(ngModel)]="searchQuery" [suggestions]="filteredItems"
                (completeMethod)="filterItems($event)" (onSelect)="redirect(searchType, $event)" field="name">
                <ng-template let-item pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        @if(searchType == 0) {
                        <app-g2g-user-tile [compact]="true" [user]="item"></app-g2g-user-tile>
                        } @else {
                        <app-video-list-tile [post]="item"></app-video-list-tile>
                        }
                    </div>
                </ng-template>
            </p-autoComplete>
        </div>

        <button type="submit" class="search-filter" aria-label="Filter search">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b0a4b9bcb881ac72d5164cb4eac24e9304e7c1e01eb9974f65bcf5f073e817db?placeholderIfAbsent=true&apiKey=37fbc956f06d49069b199c814c87453c"
                alt="" class="filter-icon">
        </button>
    </div>
</div>